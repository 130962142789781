<template>
	<div class="tr-node">
		<template v-for="(item, index) in children">
			<ul :key="item.id">
				<li :class="'level-' + level" class="li-name-box">
					<i v-if="level !== 4" :class="{'sdnm-triangle_bottom': item.showChild, 'sdnm-triangle_right': !item.showChild}" @click="showContent(index, item.id)"></i>
					{{item.name}}
				</li>
				<li class="li-operation-box">
					<a @click="newChild(item.name, item.id, index)" v-if="level !== 4">添加子类</a>
					<a @click="editInfo(item.id, index)">编辑</a>
					<a @click="isSure(1, index, item.id)">删除</a>
				</li>
			</ul>
			<tr-node
				v-on="$listeners"
				v-if="item.children && item.showChild"
				:level="level + 1"
				:children="item.children"
				:indexStr="indexStr !== '' ? indexStr + ',' + clickIndex : clickIndex + ''"
			></tr-node>
		</template>
	</div>
</template>
 
<script>
export default {
	name: 'trNode',
	props: {
		children: {
			type: Array,
			default: [],
		},
		indexStr: {
			type: String,
			default: '',
		},
		level: {
			type: Number,
			default: 0,
		},
	},
	inheritAttrs: false,
	data(){
		return {
			clickIndex: '',
		};
	},
	methods: {
		change(item){
			item.show = !item.show;
		},
		showContent(index, id){
			this.clickIndex = index;
			this.$emit('showContent', index, id, this.indexStr);
		},
		newChild(name, id, index){
			this.$emit('newChild', name, id, index, this.indexStr);
		},
		editInfo(id, index){
			this.$emit('editInfo', id, index, this.indexStr);
		},
		isSure(type, index, id){
			this.$emit('isSure', type, index, id, this.indexStr);
		},
	},
	mounted(){
	},
};
</script>