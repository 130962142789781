<template>
  <div class="wrapper" id="category">
    <ctheader></ctheader>
    <div ref="category" class="content">
      <div class="table-head">
        <div class="right">
          <!-- <div class="importData">
						<span>批量导入商品分类</span>
						<div class="sub-item">
							<button class="btn secondary" @click="downloadModel" :disabled="modelDisadled">下载导入模板</button>
							<a :href="modelUrl" v-click="downloadMod"></a>
							<file-upload
								v-model="files"
								:post-action="postAction"
								:data="excelData"
								:size="size"
								:timeout="timeout"
								@input-file="inputFile"
								ref="upload">
								<div>批量导入商品分类</div>
							</file-upload>
						</div>
					</div> -->
          <load-button
            class="btn"
            :class="{ secondary: !disabledExport, primary: disabledExport }"
            btnName="导出分类"
            :disabled="disabledExport"
            :callback="loadCallback"
          >
          </load-button>
          <button class="btn primary" @click="addCategory">添加分类</button>
        </div>
      </div>
      <div class="ul-scroll">
        <div class="tr-node">
          <ul>
            <li class="li-name-box thead">分类名称</li>
            <li class="li-operation-box thead">操作</li>
          </ul>
        </div>
        <div class="tr-node empty" v-if="dataInfo.length === 0">
          没有相关记录
        </div>
        <tr-node
          :children="dataInfo"
          indexStr=""
          @showContent="showContent"
          @newChild="newChild"
          @editInfo="editInfo"
          @isSure="isSure"
        ></tr-node>
      </div>
    </div>
    <layers
      class="category-layer"
      :show="showCategory"
      :btnLoadDisabled="btnLoadDisabled"
      :title="lookCategory"
      :width="500"
      :showFoot="true"
      :closeCallBack="cancel"
      :saveCallBack="save"
    >
      <div class="form">
        <div class="field" v-if="showAddChild">
          <label>上级分类：</label>
          <input
            type="text"
            class="w300 disabld"
            disabled
            readonly
            v-model="father"
          />
        </div>
        <div class="field">
          <label>分类名称：</label>
          <input
            type="text"
            class="w300"
            v-model="newCategory"
            maxlength="10"
          />
        </div>
      </div>
    </layers>
    <!-- <mimesis :show="deleteMimesisShow"
			:closeCallBack="deleteMimesisCancel"
			:saveCallBack="stillDelete"
			:btnLoadDisabled="disabled"
			:showIcon="true"
			:isDangerous="true"
			saveBtn="确认删除">
			<div>确认删除吗？</div>
		</mimesis> -->
    <mimesis
      :show="dbShow"
      :title="dbTitle"
      :closeCallBack="dbCancel"
      :saveCallBack="dbEnsure"
      :btnLoadDisabled="dbDisabled"
      :showIcon="true"
      :isDangerous="dbColor"
      :saveBtn="dbSave"
    >
      <div>{{ dbContent }}</div>
    </mimesis>
  </div>
</template>

<script>
/**
 * @module app/product/category
 * @desc 传蔬农贸版 商品分类管理
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-09-29
 * @copyright 2021
 */
// import layers from '@@/layers';
// import mimesis from '@@/mimesis';
// import ctheader from '@@/ctheader';
// import loadBtn from '@@/loadBtn';
import layers from "../../components/layers";
import mimesis from "../../components/mimesis";
import ctheader from "../../components/ctheader";
import loadBtn from "../../components/loadBtn";
import fileUpload from "vue-upload-component";
import { tableResize, apiPost } from "../../modules/utils";
import APIs from "../../modules/APIs";
import trNode from "./trNode";
import { mapActions } from "vuex";
const actions = mapActions(["setAlert"]);
export default {
  name: "category",
  data() {
    return {
      dataInfo: [], // 分类的数据
      chooseList: [], // 选择的id
      tbHeight: 0, // 表格高度
      showCategory: false, // 显示添加分类弹框
      showAddChild: false, // 显示子类分类弹框
      lookCategory: "添加分类", // 添加分类弹框标题
      father: "", // 添加子类分类弹框内容
      indexStr: "",
      newCategory: "", // 新增分类名称
      prev: "", // 父数据
      newCategoryId: "", // 编辑分类id
      indexNum: "", // 编辑分类索引值
      objInfo: "", // 编辑分类层级
      categoryId: 0, // 新建分类父id
      index: "", // 索引值
      id: "", // 分类id
      obj: {}, // 分类父类对象
      btnLoadDisabled: false, // layer的disable标记
      disabled: false, // mimesis的disable标记
      modelUrl: "", // 模板文件导出地址
      downloadMod: false,
      modelDisadled: false,
      files: [], // 导入分类文件的信息
      postAction: APIs.PRODUCT, // 导入分类的接口地址
      excelData: { action: "import.category" }, // 导入分类的接口请求数据
      size: 1024 * 10240, // 设置上传的excel文件大小
      timeout: 6 * 1000, // 设置上传文件超时时间
      dbShow: false, // 二次确认
      dbDisabled: false,
      dbSave: "",
      dbTitle: "",
      dbContent: "",
      dbColor: "",
      config: {}, // temp data
      // 导出
      excelUrl: "", // 文件导出地址
      download: !true, // 报表数据是否开始下载,
      disabledExport: false, // 加载中按钮样式控制
    };
  },
  directives: {
    click: {
      update(el, { value, oldValue }) {
        if (value !== oldValue && value) {
          el.click();
        }
      },
    },
  },
  components: { layers, mimesis, fileUpload, ctheader, trNode, loadBtn },
  methods: {
    ...actions,
    /**
     * 获取分类列表数据
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    getList(id, obj, index) {
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "list.category",
          pid: id,
        },
        success(res) {
          if (res.code + "" === "0") {
            if (obj === undefined) {
              $this.dataInfo = res.data.map((el) => {
                el.showChild = false;
                el.children = [];
                return el;
              });
            } else {
              obj[index].children = res.data.map((el) => {
                el.showChild = false;
                el.children = [];
                return el;
              });
            }
          } else {
            $this.setAlert({ msg: res.msg, type: false });
            if (obj !== undefined) {
              obj[index].children = [];
            }
          }
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
          if (obj !== undefined) {
            obj[index].children = [];
          }
        },
      });
    },
    /**
     * 获取需要操作的对象 针对商品分类树
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    getObj(indexStr, type) {
      let obj = this.dataInfo;
      if (indexStr !== "") {
        let levArr = indexStr.split(",");
        levArr.forEach((el, index) => {
          if (type && index === levArr.length - 1) {
            // 返回当前对象
            obj = obj[el];
            return;
          }
          // 返回当前对象的孩子
          obj = obj[el].children;
        });
      }
      return obj;
    },
    /**
     * 显示/隐藏子类
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param  {int, int, object} index, id, obj
     */
    showContent(index, id, indexStr) {
      let obj = this.getObj(indexStr);
      if (index !== "") {
        obj[index].showChild = !obj[index].showChild;
        if (obj[index].showChild) {
          this.getList(id, obj, index);
        }
      }
    },
    /**
     * 新建一级子分类
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param  {String, num} name, index
     */
    newChild(name, id, index, indexStr) {
      let obj = this.getObj(indexStr);
      this.father = name;
      this.prev = obj[index];
      this.categoryId = id;
      this.showCategory = true;
      this.showAddChild = true;
    },
    /**
     * 保存/编辑分类
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    save() {
      if (!this.newCategory) {
        this.setAlert({ msg: "请输入分类名称" });
        return;
      }
      if (this.indexNum !== "") {
        // 编辑
        this.editAjax();
        return;
      }
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "add.category",
          pid: $this.categoryId,
          name: $this.newCategory,
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            let obj = res.data;
            obj.showChild = false;
            obj.children = [];
            if ($this.showAddChild) {
              // 子类 保存 需要处理父级,然后把子塞进去
              $this.prev.children.unshift(obj);
              $this.newCategory = "";
            } else {
              // 首级 保存 无父级处理，塞进去

              $this.dataInfo.unshift(obj);
              $this.newCategory = "";
            }
            // $this.cancel();
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
          $this.btnLoadDisabled = false;
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
          $this.btnLoadDisabled = false;
        },
      });
    },
    /**
     * 编辑分类
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    editInfo(id, index, indexStr) {
      let obj = this.getObj(indexStr, 1);
      console.log(indexStr, obj);
      this.showAddChild = indexStr !== "";
      if (indexStr === "") {
        // 第一级
        this.prev = obj[index];
        this.newCategory = obj[index].name;
        this.newCategoryId = obj[index].id;
      } else {
        // 子级
        this.prev = obj;
        this.father = obj.name;
        this.newCategory = obj.children[index].name;
        this.newCategoryId = obj.children[index].id;
      }
      this.indexNum = index;
      this.indexStr = indexStr;
      this.lookCategory = "编辑分类";
      this.showCategory = true;
    },
    /**
     * 编辑分类接口
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    editAjax() {
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "edit.category",
          id: $this.newCategoryId,
          name: $this.newCategory,
          status: 1,
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            if ($this.indexStr === "") {
              // 第一级
              $this.prev.name = $this.newCategory;
            } else {
              // 子级
              $this.prev.children[$this.indexNum].name = $this.newCategory;
            }
            $this.cancel();
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
          $this.btnLoadDisabled = false;
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
          $this.btnLoadDisabled = false;
        },
      });
    },
    /**
     * 关闭弹出框
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    cancel() {
      this.showCategory = false;
      this.showAddChild = false;
      this.categoryId = 0;
      this.newCategory = "";
      this.first = {};
      this.indexNum = "";
      this.indexStr = "";
      this.lookCategory = "添加分类";
      this.getList(0);
    },
    /**
     * 添加商品分类导入
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param  file   newFile 选择的导入文件
     */
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
      if (newFile && oldFile) {
        // 开始上传
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);
        }

        // 上传进度
        if (newFile.progress !== oldFile.progress) {
        }

        // 上传错误
        if (newFile.error !== oldFile.error) {
          this.setAlert({ msg: "导入商品分类数据失败", type: false });
        }

        // 上传成功
        if (newFile.success !== oldFile.success) {
          let res = newFile.response;
          if (typeof res === "string") {
            // 为了解决莫名出现的json返回问题，该问题后端还没找到原因，暂时先这样处理
            res = JSON.parse(res);
          }
          if (res.code === "0") {
            let info = "商品分类";
            this.setAlert({
              msg: `导入${res.data.success_num}条${info}成功`,
              type: true,
            });
            this.getList(0);
          } else if (res.code === "-1") {
            let d = res.data;
            this.isSure(2, d.success_num, d.fail_num);
            this.config.tempUrl = d.url;
            this.getList(0);
          } else {
            this.setAlert({ msg: newFile.response.msg, type: false });
          }
        }
      }
    },
    downloadModel(type) {
      this.modelDisadled = true;
      this.outModel(1);
    },
    /**
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param {Number} type 1->导入模板，2->导入失败下载
     */
    outModel(type) {
      let url =
        type === 1
          ? "/api/export?service_name=ProductService&service_method=export_category_template&service_namespace=Product"
          : this.config.tempUrl;
      this.modelUrl = url;
      window.open(this.modelUrl);
      if (type === 1) {
        this.modelDisadled = false;
      } else {
        this.dbDisabled = false;
        this.dbCancel();
      }
    },
    /**
     * 新建分类
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    addCategory() {
      this.showCategory = true;
    },
    /**
     * 删除商品 二次确认操作dbEnsure -> 确认,dbCancel -> 取消
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     * @param  {Number}   二次确认类型 1->删除，2->下载弹框
     */
    isSure(type, index, id, indexStr) {
      this.config.dbType = type;
      if (type === 2) {
        let msg = "商品分类";
        this.dbTitle = `成功导入${index}条${msg}信息`;
        this.dbContent = `尚有${id}条${msg}信息有误，请下载有误的${msg}信息！`;
        this.dbSave = "下载有误的Excel文档";
        this.dbColor = false;
      } else {
        this.dbTitle = "提示";
        this.dbColor = true;
      }
      if (type === 1) {
        this.dbContent = "确认删除吗？";
        this.dbSave = "确认删除";
        this.index = index;
        this.id = id;
        this.obj = this.getObj(indexStr);
      }
      this.dbShow = true;
    },
    dbCancel() {
      this.index = "";
      this.id = "";
      this.obj = {};
      this.config.dbType = "";
      this.dbShow = false;
    },
    dbEnsure() {
      this.dbDisabled = true;
      let type = this.config.dbType;
      if (type === 1) {
        this.stillDelete();
      } else if (type === 2) {
        this.outModel(2);
      }
    },
    /**
     * 删除的二次确认的依旧删除
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    stillDelete() {
      let $this = this;
      apiPost({
        url: APIs.PRODUCT,
        data: {
          action: "delete.category",
          id: $this.id,
        },
        success(res) {
          if (res.code + "" === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.obj.splice($this.index, 1);
            $this.dbCancel();
          } else {
            $this.setAlert({ msg: res.msg, type: false });
          }
          $this.dbDisabled = false;
        },
        error(err) {
          $this.setAlert({ msg: err, type: false });
          $this.dbDisabled = false;
        },
      });
    },
    /**
     * 自适应表格高度
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-08
     * @version  0.1.0
     */
    tResize() {
      this.tbHeight = tableResize(
        this.$refs.category.clientHeight,
        200,
        this.tbody.length
      );
    },
    /**
     * 加载按钮回调方法
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-03
     * @version  0.1.0
     */
    loadCallback() {
      this.disabledExport = true;
      this.outExcel();
    },
    /**
     * 导出列表
     * @Author   songkexin   <songkexin@rongyi.com>
     * @DateTime 2021-01-03
     * @version  0.1.0
     */
    outExcel() {
      this.excelUrl =
        "/api/export?service_name=ProductService&service_method=export_category_data&service_namespace=Product";
      window.open(this.excelUrl);
      this.disabledExport = false;
    },
  },
  created() {
    this.getList(0);
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#category {
  table {
    width: 100%;
    > thead {
      .bgc(#fff);
      > tr {
        .brd-b(1px, solid, #eceff3);
      }
    }
  }
  .pdl18 {
    .pdl(18px);
  }
  .pdl54 {
    .pdl(54px);
  }
  .category-layer .form {
    .mgt(20px);
    > .field > label {
      .mgl(20px);
    }
  }
  .importData {
    .pos-r();
    .dib();
    height: 30px;
    .mgr(8px);
    .fc(12px, #525e6e);
    .va-t();
    .brd(1px, solid, #a0b9db);
    .pdl(14px);
    .pdr(14px);
    .sub-item {
      height: 60px;
      > button {
        border: none;
        .mgr(0);
      }
      .dn();
    }
    &:hover {
      .fgc(#257873);
      .brd(1px, solid, #257873);
      .sub-item {
        text-align: center;
        position: absolute;
        left: -1px;
        top: 28px;
        width: 126px;
        z-index: 1;
        .bgc(#fff);
        .brd(1px, solid, #a0b9db);
        .db();
        > label > div {
          width: 102px;
          .fc(12px, #525e6e);
          .cs();
          &:hover {
            .fgc(#257873);
          }
        }
      }
    }
  }
  .ul-scroll {
    height: ~"calc(100% - 100px)";
    overflow: auto;
  }
  ul {
    display: table;
    width: 100%;
    .mg(0);
    .pdl(10px);
    .fc(12px, #525e6e);
    .brd-b(1px, solid, #eceff3);
    li {
      .hlh(40px, 40px);
      display: table-cell;
      &.thead {
        font-weight: bold;
      }
      &.level-1 {
        .pdl(24px);
      }
      &.level-2 {
        .pdl(24 * 2px);
      }
      &.level-3 {
        .pdl(24 * 3px);
      }
      &.level-4 {
        .pdl(24 * 4px);
      }
      &.li-operation-box {
        width: 250px;
        a {
          .fc(12px, #11aac7);
          text-decoration: none;
          cursor: pointer;
          &:after {
            content: "";
            width: 1px;
            height: 100%;
            border-right: 1px solid #ececec;
            margin: 0 10px;
          }
          &:last-child:after {
            border-right: 0;
          }
        }
      }
    }
  }
  .tr-node.empty {
    .ta-c();
    .fc(12px, #525e6e);
    .hlh(40px, 40px);
    .brd-b(1px, solid, #eceff3);
  }
  .load-btn {
    vertical-align: baseline;
  }
}
</style>
